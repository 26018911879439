'use client';

import { Image, Link } from '@akinon/next/components';
import { WidgetResultType } from '@akinon/next/types';
import { SwiperReact, SwiperSlide } from '@theme/components';
import {
  HomePageFiveImagesContentValue,
  HomepageFiveImagesType
} from '@theme/types';

type HomepageFiveImagesProps = {
  data: WidgetResultType<HomepageFiveImagesType>;
};

type ItemProps = {
  item: HomePageFiveImagesContentValue;
};

const breakpoints = {
  0: {
    slidesPerView: 4,
    spaceBetween: 6
  }
};

function MobileSliderItem({ item }: ItemProps) {
  return (
    <Link
      href={item?.value?.url || '#'}
      target={item?.value?.is_target_blank == 'True' ? '_blank' : '_self'}
      className="flex flex-col md:items-center"
    >
      <div className="px-5 pb-2">
        <div
          className="flex max-h-[66px] min-w-[65px] items-center justify-center rounded-[18px] px-[11px] py-[18px] md:h-auto md:max-w-max"
          style={{ backgroundColor: item?.value?.background_color }}
        >
          <Image
            src={item?.kwargs?.value?.image?.url}
            alt={item?.value?.alt ?? 'lulu'}
            fill
            aspectRatio={58 / 52}
            sizes="100vw"
            className="object-cover"
          />
        </div>
      </div>
      <h3 className="line-clamp-2 lg:line-clamp-1 min-h-[22px] max-w-[105px] text-ellipsis text-center font-sans font-medium leading-[21px] text-black">
        {item?.value?.title}
      </h3>
    </Link>
  );
}

function DesktopItem({ item }: ItemProps) {

  return (
    <Link
      href={item?.value?.url || '#'}
      target={item?.value?.is_target_blank == 'True' ? '_blank' : '_self'}
      className="flex flex-col items-center justify-center gap-[14px]"
    >
      <div className="px-5 pb-2">
        <div
          className="flex min-h-[140px] min-w-[140px] items-center justify-center rounded-[24px] px-[11px] py-[18px]"
          style={{ backgroundColor: item?.value?.background_color }}
        >
          <Image
            src={item?.kwargs?.value?.image?.url}
            alt={item?.value?.alt ?? 'lulu'}
            aspectRatio={72 / 52}
            sizes='72px'
            className="object-cover"
            fill
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-[9px] text-center">
        <h3 className="line-clamp-1 font-sans text-base font-normal leading-4">
          {item?.value?.title}
        </h3>
        <span className="lg:max-w-[147px] text-center text-sm leading-5 text-[#9D9D9D]">
          {item?.value?.description}
        </span>
      </div>
    </Link>
  );
}

export default function HomePageFiveImagesContent({
  data
}: HomepageFiveImagesProps) {
  return (
    <>
      {/* Mobile Slider */}
      <div className="-mr-6 sm:mr-6 -ml-4 block lg:hidden">
        <SwiperReact breakpoints={breakpoints}>
          {data?.attributes?.homepage_five_images?.map((item, index) => (
            <SwiperSlide key={`mobile-${index}`}>
              <MobileSliderItem item={item} />
            </SwiperSlide>
          ))}
        </SwiperReact>
      </div>

      {/* Desktop */}
      <div className="hidden grid-cols-5 items-center justify-center gap-[84px] lg:grid lg:px-10 xl:px-32">
        {data?.attributes?.homepage_five_images?.map((item, index) => (
          <DesktopItem key={`desktop-${index}`} item={item} />
        ))}
      </div>
    </>
  );
}
