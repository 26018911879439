'use client';

import React from 'react';
import {
  Link,
  SwiperPagination,
  SwiperReact,
  SwiperSlide
} from '@theme/components';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';

export default function LandingMultipleSliderContent({ data }) {
  const imageRatio = {
    mobile: 380 / 171,
    desktop: 1340 / 310
  };

  if (data?.attributes?.multiple_slider.length === 0) {
    return null;
  }

  return (
    <section className="m-auto px-[1.125rem] lg:max-w-[90rem] lg:px-[3.125rem]">
      <SwiperReact
        modules={[SwiperPagination]}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
          bulletClass: 'swiper-pagination-bullet bg-black',
          bulletActiveClass:
            'swiper-pagination-bullet-active !bg-white !w-[16px] md:!w-[70px] !rounded-[10px]'
        }}
      >
        {data?.attributes?.multiple_slider.map((item, i) => (
          <SwiperSlide key={i}>
            <Link href={item?.value?.url ?? '#'}>
              <Image
                src={item?.kwargs?.value?.mobile_image?.url}
                alt={item?.value?.alt}
                aspectRatio={imageRatio.mobile}
                draggable={false}
                width={380}
                height={171}
                className="block md:hidden"
                imageClassName="rounded-2xl w-[380px] h-[171px]"
              />

              <Image
                src={item?.kwargs?.value?.image?.url}
                alt={item?.value?.alt}
                aspectRatio={imageRatio.desktop}
                draggable={false}
                width={1340}
                height={310}
                className="hidden md:block"
                imageClassName="rounded-2xl w-[1340px] h-[310px]"
              />
            </Link>
          </SwiperSlide>
        ))}

        {data?.attributes?.multiple_slider?.length > 1 && (
          <div
            className={clsx('swiper-pagination mb-[-3px] lg:mb-[9px]')}
          ></div>
        )}
      </SwiperReact>
    </section>
  );
}
