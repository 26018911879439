'use client';

import { useEffect } from 'react';
import { useGetOrdersQuery } from '@akinon/next/data/client/account';
import { useLocalization } from '@akinon/next/hooks';
import { LoaderSpinner } from '@theme/components';
import RecommendationTabViewContent from '@theme/views/widgets/recommendation-tab-view-content';
import { useSession } from 'next-auth/react';

export default function OrderAgain() {
  const { status } = useSession();
  const {
    data: orders,
    isLoading: ordersLoading,
    isSuccess: ordersSuccess
  } = useGetOrdersQuery(
    {},
    {
      skip: status === 'unauthenticated' || status === 'loading'
    }
  );

  useEffect(() => {
    if (status === 'unauthenticated') {
      const hideElement = document.getElementById('hide-element');
      if (hideElement) {
        const parentElement = hideElement.closest('.container');
        if (parentElement) {
          parentElement.classList.add('hidden');
        }
      }
    }
  if (status === 'authenticated' && !orders?.results) {
    const hideElement = document.getElementById('hide-element');
    if (hideElement) {
      const parentElement = hideElement.closest('.container');
      if (parentElement) {
        parentElement.classList.add('hidden');
      }
    }
  }
  }, [status]);

  if (status === 'loading') {
    return <LoaderSpinner />;
  }

  if (status === 'unauthenticated') {
    return <div id="hide-element"></div>;
  }

  if (status === 'authenticated' && orders?.results) {
    return <OrdersWidget />;
  }
}

const OrdersWidget = () => {
  const { t } = useLocalization();
  const { data: orders, isLoading } = useGetOrdersQuery({ limit: 5 });

  if (isLoading) {
    return <LoaderSpinner />;
  }

  if (!orders || !orders.results) {
    return null;
  }

  const uniqueProducts = new Set();
  const allItems = orders.results.flatMap((order) => order.orderitem_set);
  const filteredItems = allItems.filter((item) => {
    const isNewProduct = !uniqueProducts.has(item.product.pk);
    const isHiddenProduct =
      item.product.attributes.misc_product_hide === 'hide'; // Hide donation products

    if (isNewProduct && !isHiddenProduct) {
      uniqueProducts.add(item.product.pk);
    }

    return isNewProduct && !isHiddenProduct;
  });
  if (!filteredItems.length) {
    return null;
  }
  return (
    <div>
      <h3 className="font-sans text-base font-semibold leading-normal text-black lg:text-2xl lg:font-medium">
        {t('account.base.menu.order_again')}
      </h3>
      <RecommendationTabViewContent
        data={{}}
        hidePrice={true}
        isOrderAgain={true}
        recommendationData={filteredItems.map((item) => ({
          products: [item.product],
          slug: item.product.slug,
          title: item.product.name,
          imageProps: item.product.image
        }))}
      />
    </div>
  );
};
