'use client';

import { Button, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';

export default function HomeSlider({ data }: { data: any }) {
  if (Object.keys(data.attributes).length === 0) return null;

  return (
    <div className="mx-auto mt-6 pe-0 ps-6 lg:container">
      <div className="desktop-images hidden grid-cols-3 gap-5 lg:grid">
        <div className="left">
          {data?.attributes?.left_banner?.kwargs?.value?.left_banner_image
            ?.url && (
            <Link
              className="relative block h-full"
              href={data?.attributes?.left_banner?.value?.left_banner_link}
            >
              <Image
                src={
                  data?.attributes?.left_banner?.kwargs?.value
                    ?.left_banner_image?.url
                }
                alt="Special Day Banner"
                width={300}
                height={300}
                imageClassName="w-full object-cover h-full rounded-[16px]"
                className="h-full w-full"
              />
              <div className="absolute start-[45px] top-[39px] text-[40px] font-bold">
                <h3 className="leading-tight">
                  {data?.attributes?.left_banner?.value?.left_banner_text}
                </h3>
                {data?.attributes?.left_banner?.value
                  ?.left_banner_button_text && (
                  <Button className="mt-2 h-[49px] w-[151px] border-none bg-white text-base font-normal text-black">
                    {
                      data?.attributes?.left_banner?.value
                        ?.left_banner_button_text
                    }
                  </Button>
                )}
              </div>
            </Link>
          )}
        </div>
        <div className="center">
          <div className="center-top-image h-[288px]">
            {data?.attributes?.center_banners?.kwargs?.value
              ?.center_banner_top_image?.url && (
              <Link
                className="relative block"
                href={
                  data?.attributes?.center_banners?.value
                    ?.center_banner_top_image_link
                }
              >
                <Image
                  src={
                    data?.attributes?.center_banners?.kwargs?.value
                      ?.center_banner_top_image?.url
                  }
                  alt={
                    data?.attributes?.center_banners?.value
                      ?.center_banner_image_text
                  }
                  width={300}
                  height={300}
                  imageClassName="w-full object-cover h-[288px] object-top rounded-[16px]"
                  className="w-full"
                />
                <h3 className="absolute bottom-6 left-6 z-10 w-[60%] text-[40px] font-bold leading-tight text-white">
                  {
                    data?.attributes?.center_banners?.value
                      ?.center_banner_image_text
                  }
                </h3>
              </Link>
            )}
          </div>
          <div className="center-bottom-images mt-5 flex gap-5">
            {data?.attributes?.center_banners?.kwargs?.value
              ?.center_banner_bottom_image_left?.url && (
              <Link
                href={
                  data?.attributes?.center_banners?.value
                    ?.center_banner_bottom_image_left_link
                }
                className="w-1/2"
              >
                <Image
                  src={
                    data?.attributes?.center_banners?.kwargs?.value
                      ?.center_banner_bottom_image_left?.url
                  }
                  alt="Special Day Banner"
                  width={300}
                  height={300}
                  imageClassName="w-full h-[227px] object-cover object-left-top	 rounded-[16px]"
                  className="w-full"
                />
              </Link>
            )}

            {data?.attributes?.center_banners?.kwargs?.value
              ?.center_banner_bottom_image_right?.url && (
              <Link
                href={
                  data?.attributes?.center_banners?.value
                    ?.center_banner_bottom_image_right_link
                }
                className="w-1/2"
              >
                <Image
                  src={
                    data?.attributes?.center_banners?.kwargs?.value
                      ?.center_banner_bottom_image_right?.url
                  }
                  alt="Special Day Banner"
                  width={300}
                  height={300}
                  imageClassName="w-full h-[227px]  object-cover object-left-top rounded-[16px]"
                  className="w-full"
                />
              </Link>
            )}
          </div>
        </div>

        <div className="right">
          {data?.attributes?.right_banner?.kwargs?.value?.right_banner_top_image
            ?.url && (
            <Link
              href={
                data?.attributes?.right_banner?.value
                  ?.right_banner_top_image_link
              }
              className="relative mb-5 block h-[365px]"
            >
              <Image
                src={
                  data?.attributes?.right_banner?.kwargs?.value
                    ?.right_banner_top_image?.url
                }
                alt="Special Day Banner"
                width={300}
                height={300}
                imageClassName="w-full object-cover object-top h-[365px] rounded-[16px]"
                className="mb-5 w-full"
              />
            </Link>
          )}

          {data?.attributes?.right_banner?.kwargs?.value
            ?.right_banner_bottom_image?.url && (
            <Link
              href={
                data?.attributes?.right_banner?.value
                  ?.right_banner_bottom_image_link
              }
              className="relative block"
            >
              <Image
                src={
                  data?.attributes?.right_banner?.kwargs?.value
                    ?.right_banner_bottom_image?.url
                }
                alt="Special Day Banner"
                width={300}
                height={300}
                imageClassName="w-full object-cover object-top h-[150px] rounded-[16px]"
                className="w-full"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
