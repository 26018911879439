'use client';

import { useLocalization } from '@akinon/next/hooks';
import { WidgetResultType } from '@akinon/next/types';
import { Button, Icon, Link, SwiperAutoplay } from '@theme/components';
import { SliderButton } from '@theme/components/slider-button';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { RecommendationTabViewType } from '@theme/types';
import { ProductItemDefault as ProductItem } from '@theme/views/product-item/templates/default';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

type RecommendationTabViewContentProps = {
  data: WidgetResultType<RecommendationTabViewType>;
  recommendationData: WidgetResultType<{ title: string }>[];
  hidePrice?: boolean;
  isOrderAgain?: boolean;
  imageProps?: any;
  layoutPage?: boolean;
};

export default function RecommendationTabViewContent({
  data,
  recommendationData,
  hidePrice = false,
  isOrderAgain,
  imageProps,
  layoutPage
}: RecommendationTabViewContentProps) {
  const { t, locale } = useLocalization();
  const { width } = useWindowSize();
  const [clientWidth, setClientWidth] = useState<number | null>(null);
  useEffect(() => {
    setClientWidth(width);
  }, [width]);
  const [activeRecommendation, setActiveRecommendation] = useState('All');
  const [activeProduct, setActiveProduct] = useState(
    recommendationData && recommendationData?.flatMap((item) => item?.products)
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);

  const isStart = activeIndex === 0;
  useEffect(() => {
    if (swiperRef.current?.swiper?.params) {
      const slidesPerView =
        activeProduct.length <= 5
          ? Math.floor(swiperRef.current.swiper.params.slidesPerView || 1)
          : Math.ceil(swiperRef.current.swiper.params.slidesPerView || 1);
      const atEnd = activeIndex === activeProduct.length - slidesPerView;
      const notEnoughSlides =
        activeProduct.length < slidesPerView || activeProduct.length < 5;

      setIsEnd(atEnd || notEnoughSlides);

      if (atEnd || notEnoughSlides) {
        swiperRef.current.swiper.slideTo(
          activeProduct.length -
            Math.floor(swiperRef.current.swiper.params.slidesPerView)
        );
      }
    }

    if (activeProduct.length < 5) {
      setIsEnd(true);
    }
  }, [activeIndex, activeProduct, swiperRef]);

  const handleRecommendationClick = (recommendation: string) => {
    setActiveRecommendation(recommendation);

    if (recommendation === 'All') {
      setActiveProduct(recommendationData.flatMap((item) => item?.products));
    } else {
      const selectedRecommendation = recommendationData.filter(
        (item) => item?.slug === recommendation
      );

      setActiveProduct(
        selectedRecommendation.flatMap((item) => item?.products)
      );
    }

    setActiveIndex(0);
  };
  const handlePrevClick = () => {
    const activeProductLength = activeProduct.length === 5 ? false : true;

    if (
      isEnd &&
      activeProduct.length >
        Math.floor(swiperRef.current.swiper.params.slidesPerView) &&
      activeProductLength
    ) {
      swiperRef.current.swiper.slidePrev(
        activeProduct.length -
          Math.floor(swiperRef.current.swiper.params.slidesPerView + 4)
      );
    }

    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
    setActiveIndex(activeIndex - 1);
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    setActiveIndex(activeIndex + 1);
  };

  if (clientWidth === null) {
    return null;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="font-sans text-base font-semibold leading-normal text-black lg:text-2xl lg:font-medium">
          {data?.attributes?.title?.value}
        </h3>
        <Link
          className="block lg:hidden"
          href={data?.attributes?.url?.value || '#'}
        >
          <Icon
            name={locale?.startsWith('ar') ? 'chevron-left' : 'chevron-right'}
            size={16}
          />
        </Link>
      </div>
      <div className="mt-[22px]">
        <div className="mb-[22px] flex gap-4 lg:mb-[50px]">
          <div className="no-scrollbar flex flex-1 items-center gap-4 overflow-x-auto">
            <Swiper
              spaceBetween={12}
              slidesPerView={'auto'}
              className={`w-full justify-start`}
            >
              <SwiperSlide key="all-button" className="!w-auto">
                <Button
                  onClick={() => handleRecommendationClick('All')}
                  appearance={
                    activeRecommendation === 'All' ? 'filled' : 'outlined'
                  }
                  className={clsx(
                    'text-black" h-[30px] rounded-full border-black px-2 font-sans text-sm font-medium leading-[1] lg:h-8 lg:text-base',
                    {
                      'border-primary text-white focus:bg-primary focus:text-white':
                        activeRecommendation === 'All'
                    }
                  )}
                >
                  {t('common.widgets.All')}
                </Button>
              </SwiperSlide>
              {data?.attributes?.recommendation_tab_view?.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="!w-auto">
                    <SliderButton
                      onClick={() =>
                        handleRecommendationClick(
                          item?.value?.recommendation_slug
                        )
                      }
                      key={item?.value?.recommendation_slug}
                      appearance={
                        activeRecommendation ===
                        item?.value?.recommendation_slug
                          ? 'filled'
                          : 'outlined'
                      }
                      className={clsx(
                        'text-black" h-[30px] whitespace-nowrap rounded-full border-black px-2 font-sans text-sm font-medium leading-[1] lg:h-8 lg:text-base',
                        {
                          'border-primary text-white focus:bg-primary focus:text-white':
                            activeRecommendation ===
                            item?.value?.recommendation_slug
                        }
                      )}
                    >
                      {item?.value?.collection_title}
                    </SliderButton>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="hidden items-center lg:flex">
            <Link
              className="me-6 font-sans text-sm leading-[1.43] text-primary"
              href={data?.attributes?.url?.value || '#'}
            >
              {t('common.widgets.see_all')}
            </Link>
            <div className="flex gap-2">
              <button
                onClick={handlePrevClick}
                disabled={isStart}
                className={clsx(
                  'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black',
                  {
                    'cursor-not-allowed opacity-20': isStart
                  }
                )}
              >
                <Icon
                  name={
                    locale?.startsWith('ar') ? 'chevron-right' : 'chevron-left'
                  }
                  size={24}
                />
              </button>
              <button
                onClick={handleNextClick}
                disabled={isEnd}
                className={clsx(
                  'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-black',
                  {
                    'cursor-not-allowed opacity-20': isEnd
                  }
                )}
              >
                <Icon
                  name={
                    locale?.startsWith('ar') ? 'chevron-left' : 'chevron-right'
                  }
                  size={24}
                />
              </button>
            </div>
          </div>
        </div>
        <Swiper
          ref={swiperRef}
          modules={[SwiperAutoplay]}
          spaceBetween={clientWidth < 768 ? 26 : layoutPage ? 16 : 18}
          slidesPerView={
            clientWidth < 480
              ? 1.9
              : clientWidth < 768
                ? 3
                : clientWidth < 1024
                  ? 5
                  : layoutPage
                    ? 6.175
                    : 4.8
          }
          className="!relative"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
        >
          {activeProduct.map((product, index) => (
            <SwiperSlide key={index}>
              <ProductItem
                key={product.pk}
                product={product}
                ImageWidth={254}
                ImageHeight={238}
                mobileImageWidth={176}
                mobileImageHeight={215}
                index={index}
                hidePrice={hidePrice}
                isOrderAgain={isOrderAgain}
                imageUrl={imageProps ? imageProps : product.image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
