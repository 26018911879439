'use client';

import SpecialDealsContent from '@theme/views/widgets/special-deals-content';
import { SwiperReact, SwiperSlide } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { useRef } from 'react';

export default function SpecialDeals({ data }) {
  const { width } = useWindowSize();
  const swiperRef = useRef(null);

  return (
    <div className="pe-0 ps-6 lg:container">
      <p className="mb-4 text-base font-semibold lg:text-2xl">
        {width < 768
          ? data?.attributes?.mobile_title?.value
          : data?.attributes?.title?.value}
      </p>

      <SwiperReact
        spaceBetween={width < 768 ? 12 : 16}
        slidesPerView={width < 768 ? 2.15 : 5}
        onInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="!relative"
      >
        {data?.attributes?.special_deals.map((item, index) => (
          <SwiperSlide key={index}>
            <SpecialDealsContent data={item} />
          </SwiperSlide>
        ))}
      </SwiperReact>
    </div>
  );
}
