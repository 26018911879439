'use client';

import { useState, useRef } from 'react';
import { Image } from '@akinon/next/components';
import { Icon } from '@theme/components';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { Link } from '@theme/components';

export default function FlatpageSpecialDealsContent({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      const video = videoRef.current as HTMLVideoElement;

      isPlaying ? video.pause() : video.play();
      setIsPlaying(!isPlaying);
    }
  };

  const file = isMobile
    ? data?.kwargs?.value?.mobile_file?.url
    : data?.kwargs?.value?.file?.url;

  return (
    <div className="relative flex h-[298px] overflow-hidden rounded-2xl lg:h-[414px]">
      {file?.includes('.mp4') ? (
        <div className="relative h-[298px] rounded-2xl lg:h-[414px]">
          <video
            ref={videoRef}
            className="hidden h-full w-full rounded-2xl object-cover lg:block"
            width={255}
            height={414}
            src={file}
            onEnded={() => setIsPlaying(false)}
            muted
          />
          <video
            ref={videoRef}
            className="block h-full w-full rounded-2xl object-cover lg:hidden"
            width={255}
            height={298}
            src={file}
            onEnded={() => setIsPlaying(false)}
            muted
          />
          {isPlaying ? (
            <div
              className="absolute bottom-0 start-0 flex cursor-pointer items-center justify-center opacity-70 hover:scale-125 hover:opacity-100"
              onClick={toggleVideoPlayback}
            >
              <Icon name="video-pause" size={40} className="z-10 text-white" />
            </div>
          ) : (
            <div
              className="absolute inset-0 flex cursor-pointer items-center justify-center"
              onClick={toggleVideoPlayback}
            >
              <div className="absolute inset-0 rounded-2xl bg-[#838383] opacity-40"></div>
              <Icon name="video-play" size={40} className="z-10 text-white" />
            </div>
          )}
        </div>
      ) : (
        <div>
          {data?.value.redirect_url ? (
            <>
              <Link href={data?.value.redirect_url}>
                <Image
                  imageClassName="rounded-2xl object-cover h-[414px] h-full hidden lg:block"
                  className="rounded-2xl"
                  width={255}
                  height={414}
                  src={file}
                  alt={data?.value?.file_alt_text}
                />
              </Link>
              <Link href={data?.value.redirect_url}>
                <Image
                  imageClassName="rounded-2xl object-cover h-[298px] block lg:hidden"
                  className="rounded-2xl"
                  width={255}
                  height={298}
                  src={file}
                  alt={data?.value?.file_alt_text}
                />
              </Link>
            </>
          ) : (
            <>
              <Image
                imageClassName="rounded-2xl object-cover h-[414px] h-full hidden lg:block"
                className="rounded-2xl"
                width={255}
                height={414}
                src={file}
                alt={data?.value?.file_alt_text}
              />
              <Image
                imageClassName="rounded-2xl object-cover h-[298px] block lg:hidden"
                className="rounded-2xl"
                width={255}
                height={298}
                src={file}
                alt={data?.value?.file_alt_text}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
