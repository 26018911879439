'use client';

import { useGetClientWidgetDataHandler } from '@theme/hooks';
import { Link } from '@theme/components';
import { twMerge } from 'tailwind-merge';

type LinkType = {
  url: string;
  subtitle: string;
};

type CategoryItem = {
  title: string;
  links: LinkType[];
  value: {
    category_title: string;
    category_sub_url: string;
    category_subtitle: string;
  };
};

type CategoryType = {
  categories?: CategoryItem[];
  title?: {
    value: string;
  };
  description?: {
    value: string;
  };
};

type WidgetResultType<T> = {
  description: any;
  title: any;
  categories: any[];
  attributes?: T;
};

export default function FooterCategoryText(props) {
  const { data, isSuccess } = useGetClientWidgetDataHandler({
    slug: 'footer-category-text'
  });

  const categories: CategoryItem[] = data?.attributes?.categories || [];

  const groupedCategories = categories.reduce((acc, category) => {
    const {
      category_title: title,
      category_sub_url: url,
      category_subtitle: subtitle
    } = category?.value;

    if (!acc[title]) {
      acc[title] = {
        title,
        links: []
      };
    }

    acc[title]?.links?.push({ url, subtitle });

    return acc;
  }, {});

  const filteredData = Object.values(groupedCategories);

  return (
    data &&
    isSuccess && (
      <div
        className={twMerge(
          'mx-auto flex w-full flex-col',
          props.className
        )}
      >
        {data?.attributes?.title?.value && (
          <h3 className="text-[0.5rem] font-semibold text-primary lg:text-[0.625rem]">
            {data?.attributes?.title?.value}
          </h3>
        )}
        <br />
        {data?.attributes?.description?.value && (
          <div
            className="text-[0.5rem] font-semibold text-[#8E8E8E] lg:text-[0.625rem]"
            dangerouslySetInnerHTML={{
              __html: data?.attributes?.description?.value
            }}
          ></div>
        )}
        <br />
        {/* {filteredData &&
        filteredData?.map((item: CategoryItem, index) => (
          <div
            className="mb-4 flex flex-wrap items-center justify-start gap-x-1 text-wrap text-[0.5rem] font-semibold text-[#8E8E8E] last:mb-0 lg:text-[0.625rem]"
            key={index}
          >
            {item?.title}:
            {item.links.map((link: LinkType, i) => (
              <Link
                key={i}
                target="_blank"
                href={link?.url ? link.url : ''}
                className="whitespace-nowrap text-primary underline"
              >
                {link?.subtitle}
                {i !== item.links?.length - 1 && (
                  <span className="ml-1">|</span>
                )}
              </Link>
            ))}
            <br />
          </div>
        ))} */}
      </div>
    )
  );
}
